<template>
  <div id="print">
    <div v-for="item in data" :key="item.id" class="ticket-item">
      <p class="text-center title">上海奥吉实业有限公司</p>
      <p class="text-center sub-title">结费单</p>
      <ul>
        <li><span>日期：{{ item.date }}</span></li>
        <li><span>报关单号：{{ item.order_num }}</span></li>
        <li><span>计划号：{{ item.plan_id }}</span></li>
        <li><span>报关单位：{{ item.customer_name }}</span></li>
        <li><span>金额：{{ item.amount }}</span></li>
        <li>
          <div class="flex-center">
            <img :src="item.qr_code">
          </div>
        </li>
        <li>
          <span style="display:block">交易信息</span>
          <span v-for="cash in item.cash_record_list" :key="cash.id" style="display:block">
            {{ `交易号：${cash.trx_id ? cash.trx_id : ''}  交易时间：${cash.pay_date ? cash.pay_date : ''}` }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  // ,
  // created() {
  //   this.initData()
  // },
  // data() {
  //   return {
  //     currentData: {}
  //   }
  // },
  // initData() {
  //   if (this.data) {
  //     this.currentData = this.data
  //   }
  // }
  methods: {

  }
}
</script>

<style scoped>
@page{
  size: auto;
}

html{
  background-color: #FFFFFF;
  margin: 0;
}

@media print {
  html, body {
    height: inherit;
  }
}

#print {
  min-width: 300px;
  padding: 20px;
}

#print p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li:last-child {
  margin-top: 20px;
}

span {
  color: black;
  font-size: 18px;
}

.ticket-item {
  margin-bottom: 50px;
}

.text-center {
  text-align: center;
}
.title {
  font-size: 25px;
  font-weight: 600;
  color: black;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center img{
  width: 150px;
  height: 150px;
}
</style>
